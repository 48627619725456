<template>
  <div
    class="flex pt-4 border-b border-gray-300 last:border-0 pb-4 hover:cursor-pointer"
    @click="$emit('click-card')"
  >
    <div class="flex-row flex-1">
      <div class="flex">
        <h2 class="mt-0 flex-1">{{ card.name }}</h2>
        <span
          v-if="!card.active"
          class="h-6 rounded-full bg-gray-700 text-white text-sm py-0.5 px-2"
        >
          Inactief
        </span>
      </div>

      <div v-if="showDescription" class="text-sm flex-1">
        {{ cardDescription }}
      </div>
      <div class="flex w-full content-between mt-2">
        <div class="flex-1">
          <template v-if="showRemaining">
            <div class="text-base font-semi">
              Nog {{ card.cp_details.remaining }} strippen over
            </div>
          </template>
          <template v-else>
            <div class="text-base font-semi">
              {{ $n(card.cost, 'currency') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ card.details.times_to_use }} keer te gebruiken
            </div>
          </template>
        </div>
        <button
          v-if="showBuyButton"
          class="button button-primary button-sm button-icon ml-4"
          @click="() => gotoDetailPage(card.id)"
        >
          <icon-base><icon-cart /></icon-base>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase'
import IconCart from '@/components/icons/IconCart'

export default {
  name: 'CardItem',
  components: { IconBase, IconCart },
  props: {
    card: {
      type: Object,
      required: true
    },
    showBuyButton: {
      type: Boolean,
      default: true
    },
    showRemaining: {
      type: Boolean,
      default: false
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    cardDescription: function () {
      if (this.card.description && this.card.description.length > 60) {
        return this.card.description.slice(0, 60).concat(' ...')
      } else {
        return this.card.description
      }
    }
  },
  created: function () {},
  methods: {
    gotoDetailPage: function (id) {
      this.$router.push(`/cards/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
